<template>
  <div>
    asdasd
    <div class="row">
      <div class="col-xl-12 col-md-12">
        <div v-for="item in notificationTypes" :key="item.key" class="card card-psh border">
          <div class="bg-light-primary rounded-top py-2 px-1">
            <div class="psh-header mb-0 d-flex align-items-center">
              <div class="mr-1 border-0">
                <p-icon name="bi-gear-fill" size="48px" color="primary" />
              </div>
              <div class="d-flex flex-column">
                <h4 class="card-title mb-25">
                  {{ item.text }}
                </h4>
              </div>
            </div>
          </div>
          <div v-for="preference in preferences" :key="preference.key" class="row m-1 mt-3">
            <div v-if="preference.group === item.key" class="col-12 row">
              <b-table
                :items="items"
                :fields="fields"
                fixed
                class="mb-0"
              >
                <template #cell(topicName)="data">
                  {{ data.value }}
                </template>
                <template #cell()="data">
                  <dx-util-switch :value="data.value" @value-changed="updateSubscription(data)" />
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import managedKeysEnum from '@/enums/managedKeysEnum'
import useCurrentUser from '@/libs/app/current-user'
import userService from '@/http/requests/system/userService'
import NotificationTypesEnum from '@/enums/notification-types.enum'
import companyService from '@/http/requests/company/companyService'

export default {

  setup() {
    const {
      userAccountNo,
    } = useCurrentUser()
    return {
      userAccountNo,
    }
  },
  data() {
    return {
      managedKeysEnum,
      notificationTypes: NotificationTypesEnum,
      preferences: [
        managedKeysEnum.SHIPMENTBATCHEMAILNOTIFICATION,
      ],
      storeList: [],
      items: [],
      fields: [],
      selectedStore: 0,
      showTooltip: false,
      fetchedPreferences: [],
    }
  },
  computed: {
    isStoreSelected() {
      return this.selectedStore !== 0
    },
    fetchedPreferencesByStoreId() {
      return this.fetchedPreferences.filter(item => item.referenceId === this.selectedStore)
    },
  },
  watch: {
    fetchedPreferences() {
      this.fetchedPreferencesByStoreId()
      this.getUserNotificationPreferences()
    },
  },
  mounted() {
    this.getStoreList()
    this.getUserNotificationPreferences()
  },
  methods: {
    updateSubscription(data) {
      const storeId = data.field.storeId
      const settingsKey = this.preferences.find(item => item.text === data.item.topicName).key
      const topicText = data.item.topicName
      const storeName = data.field.label
      if (data.value) {
        userService.unSubscribeSettingsNotification(settingsKey, storeId)
      } else {
        const notificationDTO = {
          topicName: settingsKey,
          topicText: topicText,
          referenceId: storeId,
          referenceText: storeName,
          referenceType: 'STORE',
          enabled: true,
        }
        userService.subscribeMainTopic(notificationDTO)
      }
    },
    getUserNotificationPreferences() {
      userService.currentUserSubscribedMainTopics().then(response => {
        this.fetchedPreferences = response.data
        this.prepareRows()
      })
    },
    getStoreList() {
      companyService.fetchCompanyAndStoresByAccountNo(this.userAccountNo).then(response => {
        this.storeList = response.data.stores.map(store => ({
          key: store.id,
          text: store.name,
          value: store.id,
        }))
        if (this.storeList?.length === 1) {
          this.selectedStore = this.storeList[0].value
        }
        this.fields = this.storeList.map(store => ({
          storeId: store.key,
          key: store.text,
          label: store.text,
        }))
        this.fields.unshift({
          value: 'topicName',
          key: 'topicName',
          label: 'Topic Name',
          thStyle: { width: '30%' },
        })
      })
    },
    prepareRows() {
      this.preferences.forEach(preference => {
        const data = []
        this.fetchedPreferences.filter(item => item.key === preference.topicName).forEach(element => {
          data.push({
            topicName: element.topicText,
            [element.referenceText]: element.enabled,
          })
        })
        const temp = data.reduce((a, b) => ({ ...a, ...b }), {})
        this.items.push(temp)
      })
    },
  },
}
</script>

<style lang="scss">
</style>
