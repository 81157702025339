<template>

  <div class="container-fluid">
    <div class="row">
      <div id="account" class="col-md-12 px-0">
        <b-card no-body>
          <b-tabs
            id="accountTab"
            pills
            small
            card
            vertical
            content-class="border-left"
          >
          <!-- Change Password -->
            <b-tab active>
              <template #title>
                <feather-icon icon="LockIcon" size="18" />
                <span class="font-weight-bold mr-10">Change Password</span>
              </template>
              <account-setting-password />
            </b-tab>
            <!-- Notification Preferences -->
            <b-tab v-if="!isIndividualTenant" lazy>
              <template #title>
                <feather-icon icon="BellIcon" size="18" />
                  <span class="font-weight-bold mr-3">Notification Preferences</span>
              </template>
              <account-notification-preferences />
            </b-tab>
            <b-tab lazy>
              <template #title>
                <feather-icon icon="UserIcon" size="18" />
                  <span class="font-weight-bold mr-3">Image Management</span>
              </template>
              <logo-management />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountNotificationPreferences from './AccountNotificationPreferences.vue'
import LogoManagement from '../../apps/company/tabs/LogoManagement.vue'

export default {
  components: {
    AccountSettingPassword,
    AccountNotificationPreferences,
    LogoManagement,
  },
  data() {
    return {
      options: {},
      isIndividualTenant: false,
    }
  },
  created() {
    // eslint-disable-next-line no-undef
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData.tenantType === 'INDIVIDUAL') {
      this.isIndividualTenant = true
    }
  },
}
</script>
<style lang="scss">
#account {
  .card {
    .card-header {
      justify-content: start;
    }
  }
}
#accountTab {
  .nav-pills {
    margin-bottom: 0px !important;
    .nav-item {
      width: 100%;
      margin-bottom: 0.286rem;
      .nav-link {
        padding-left: 0.571rem;
        background-color: rgba(41, 128, 185, 0.5);
        justify-content: start !important;
        &.active{
          background-color: #2980b9;
        }
      }
    }
  }
}
</style>
