<template>
  <div class="row">
    <div class="col-xl-12 col-md-12">
      <div class="card card-psh border">
        <div class="bg-light-primary rounded-top py-2 px-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-gear-fill" size="48px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Change Password
              </h4>
            </div>
          </div>
        </div>
        <div class="card-body">
          <form method="post" @submit="handleSubmit($event)">
            <dx-util-form
              ref="changePasswordFormRef"
              :form-data="changePasswordFormData"
              :col-count="1"
              :show-colon-after-label="true"
              label-location="top"
              class="max-limit"
            >
              <dx-util-item :editor-options="passwordOptions" data-field="currentPassword">
                <dx-util-required-rule message="Current Password is required" />
              </dx-util-item>
              <dx-util-item :editor-options="passwordOptions" data-field="newPassword">
                <dx-util-required-rule message="Password is required" />
                <dx-util-pattern-rule
                  :pattern="passwordPattern"
                  message="Your password must contain at least one uppercase, one lowercase, one special character and one digit"
                />
              </dx-util-item>
              <dx-util-item :editor-options="passwordOptions" data-field="confirmNewPassword">
                <dx-util-required-rule message="Confirm Password is required" />
                <dx-util-compare-rule :comparison-target="passwordComparison" message="Password and Confirm Password do not match" />
              </dx-util-item>
              <dx-util-group-item :col-count="2">
                <dx-util-button-item
                  :button-options="saveButtonOptions"
                  horizontal-alignment="left"
                />
                <dx-util-button-item
                  :button-options="resetButtonOptions"
                  horizontal-alignment="right"
                />
              </dx-util-group-item>
            </dx-util-form>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      changePasswordFormData: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      },
      passwordPattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/,
    }
  },
  computed: {
    passwordOptions() {
      return {
        mode: 'password',
      }
    },
    saveButtonOptions() {
      return {
        text: 'Change Password',
        type: 'default',
        width: '50%',
        useSubmitBehavior: true,
      }
    },
    resetButtonOptions() {
      return {
        text: 'Reset Form',
        type: 'normal',
        width: '50%',
        useSubmitBehavior: false,
        onClick: () => {
          const form = this.$refs.changePasswordFormRef.instance
          form.resetValues()
        },
      }
    },
  },
  methods: {
    passwordComparison() {
      return this.changePasswordFormData.newPassword
    },
    handleSubmit(e) {
      const form = this.$refs.changePasswordFormRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        const data = {
          currentPassword: this.changePasswordFormData.currentPassword,
          newPassword: this.changePasswordFormData.newPassword,
        }
        this.$http.post('/api/account/change-password', data).then(result => {
          if (result.status === 200) {
            form.resetValues()
          }
        })
      }
      e.preventDefault()
    },
  },
}
</script>

<style lang="scss">
</style>
